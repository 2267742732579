import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45'),
	() => import('./nodes/46'),
	() => import('./nodes/47'),
	() => import('./nodes/48'),
	() => import('./nodes/49'),
	() => import('./nodes/50'),
	() => import('./nodes/51'),
	() => import('./nodes/52')
];

export const server_loads = [];

export const dictionary = {
		"/(app)": [8,[2]],
		"/(app)/admin": [9,[2,3]],
		"/(app)/admin/settings": [10,[2,3]],
		"/(app)/assistants": [11,[2,4]],
		"/auth": [49],
		"/auth/reset-password": [50],
		"/(app)/c": [12,[2]],
		"/(app)/c/[id]": [13,[2,5]],
		"/(app)/dashboard": [14,[2]],
		"/error": [51],
		"/(app)/quick_actions": [15,[2,6]],
		"/s/[id]": [52],
		"/(app)/tutorials": [16,[2]],
		"/(app)/workers": [17,[2]],
		"/(app)/workers/[id]": [18,[2]],
		"/(app)/workspace": [19,[2,7]],
		"/(app)/workspace/documents": [20,[2,7]],
		"/(app)/workspace/models": [21,[2,7]],
		"/(app)/workspace/models/create": [22,[2,7]],
		"/(app)/workspace/models/edit": [23,[2,7]],
		"/(app)/workspace/modules": [24,[2,7]],
		"/(app)/workspace/modules/create": [25,[2,7]],
		"/(app)/workspace/modules/edit": [26,[2,7]],
		"/(app)/workspace/playground": [27,[2,7]],
		"/(app)/workspace/prompts": [28,[2,7]],
		"/(app)/workspace/prompts/create": [29,[2,7]],
		"/(app)/workspace/prompts/edit": [30,[2,7]],
		"/(app)/workspace/quick-action-categories": [31,[2,7]],
		"/(app)/workspace/quick-action-categories/create": [32,[2,7]],
		"/(app)/workspace/quick-action-categories/edit": [33,[2,7]],
		"/(app)/workspace/quick-actions": [34,[2,7]],
		"/(app)/workspace/quick-actions/create": [35,[2,7]],
		"/(app)/workspace/quick-actions/edit": [36,[2,7]],
		"/(app)/workspace/smart-contexts": [37,[2,7]],
		"/(app)/workspace/smart-contexts/create": [38,[2,7]],
		"/(app)/workspace/smart-contexts/edit": [39,[2,7]],
		"/(app)/workspace/tools": [40,[2,7]],
		"/(app)/workspace/tools/create": [41,[2,7]],
		"/(app)/workspace/tools/edit": [42,[2,7]],
		"/(app)/workspace/tutorials": [43,[2,7]],
		"/(app)/workspace/tutorials/create": [44,[2,7]],
		"/(app)/workspace/tutorials/edit": [45,[2,7]],
		"/(app)/workspace/workers": [46,[2,7]],
		"/(app)/workspace/workers/create": [47,[2,7]],
		"/(app)/workspace/workers/edit": [48,[2,7]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';