import * as Sentry from '@sentry/sveltekit';

import { env } from '$env/dynamic/public';

Sentry.init({
	dsn: env.PUBLIC_SENTRY_DNS_FRONTEND,
	environment: env.PUBLIC_ENVIRONMENT,
	// We recommend adjusting this value in production, or using tracesSampler
	// for finer control
	tracesSampleRate: parseFloat(env.PUBLIC_SENTRY_TRACES_SAMPLE_RATE)

	// Optional: Initialize Session Replay only for production environments
	// ...(env.PUBLIC_ENVIRONMENT.includes('prod') && {
	// 	integrations: [Sentry.replayIntegration()],
	// 	replaysSessionSampleRate: 0.1,
	// 	replaysOnErrorSampleRate: 1.0
	// })
});

const myErrorHandler = ({ error, event }) => {
	console.error('An error occurred on the client side:', error, event);
};

export const handleError = Sentry.handleErrorWithSentry(myErrorHandler);

// or alternatively, if you don't have a custom error handler:
// export const handleError = handleErrorWithSentry();
